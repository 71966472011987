import React from 'react';
// import {Grid, Row, Col} from 'react-bootstrap';
// import { Jumbotron } from './jumbotron';
import {HeroVideo} from '../../components/hero/hero-video';
import {Hero} from '../../components/hero/hero';
// import { Card } from './card';
import {Faq} from '../../components/faq/faq';
import {Projects} from "../../components/projects/projects";
import {Team} from "../../components/team/team";

import heroImage from "../../assets/sagahunt-phone.png";

export const Main = () => {
    return (
        <main role="main">
            {/*<br /><br />*/}
            <Hero title="Next-gen scavenger hunt!" image={heroImage} imageDesc='' description="Building an ecosystem and
             endless utility in entertainment and games. In the process community will have fun and earn. Don't miss out
             to become an OG as we are just starting!"/>
            <Faq/>
        </main>
    );
};