/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Container, Navbar, Nav, Image, Stack} from "react-bootstrap";
import {LinkContainer} from 'react-router-bootstrap';
import logo from '../assets/sagahunt-logo.svg';
// import discordLogo from '../assets/icons8-discord.svg';
import twitterLogo from '../assets/icons8-twitter.svg';

export const Header = () => {
    return (
        <div>
            <Navbar expand="sm" className="d-block d-md-none">
                <Container className="justify-content-center">
                    <LinkContainer to="/">
                        <Navbar.Brand href="#home" className="">
                            <img src={logo} alt="Saga Hunt" className="position-relative d-inline"
                                 width="80"/>
                        </Navbar.Brand>
                    </LinkContainer>
                </Container>
            </Navbar>
            <Navbar collapseOnSelect expand="md" bg="none" variant="dark">
                <Container>
                    <LinkContainer to="/">
                        <Navbar.Brand href="#home" className="d-md-block d-sm-none">
                            <img src={logo} alt="Saga Hunt"
                                 className="xxx position-relative d-none d-md-block"
                                 width="80"/>
                        </Navbar.Brand>
                    </LinkContainer>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                        {/*<Nav className="me-auto text-center"*/}
                        {/*     style={{ maxHeight: '100px' }}*/}
                        {/*     navbarScroll>*/}
                        {/*    <Nav.Link className="justify-content-center" href="#projects">Projects</Nav.Link>*/}
                        {/*    <Nav.Link href="#team">Team</Nav.Link>*/}
                        {/*    <Nav.Link href="#faq">FAQ</Nav.Link>*/}
                        {/*</Nav>*/}
                        <Nav className="text-center">
                            {/*<LinkContainer to="/projects">*/}
                            {/*<Nav.Link href="/#projects">Projects</Nav.Link>*/}
                            {/*</LinkContainer>*/}
                            {/*<LinkContainer to="/team">*/}
                            {/*    <Nav.Link href="#team">Team</Nav.Link>*/}
                            {/*</LinkContainer>*/}
                            <Nav.Link href="/#faq">FAQ</Nav.Link>
                            {/*<LinkContainer to="#faq">*/}
                            {/*    <Nav.Link href="#faq">FAQ</Nav.Link>*/}
                            {/*</LinkContainer>*/}
                        </Nav>
                    </Navbar.Collapse>
                    <Stack direction="horizontal" className="ms-3 d-none d-sm-none d-md-flex" gap={3}>
                        <div className="vr me-4"/>
                        <a href="https://twitter.com/SagaHunt" target="_blank"
                           className="border p-2 shadow-lg"><Image src={twitterLogo} width="32"/></a>
                        {/*<a href="https://discord.gg/xzBxjf5yMS" target="_blank" className="border p-2 shadow-lg"><Image*/}
                        {/*    src={discordLogo} width="32"/></a>*/}
                    </Stack>
                </Container>
            </Navbar>
        </div>
    );
};