import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import './css/style.scss';
import './index.css';
import App from './App';
import {Main} from './pages/SolPhantoms/main';
import {Header} from "./components/header";
import {Projects} from "./components/projects/projects";
import {Team} from "./components/team/team";
import {Faq} from "./components/faq/faq";
import reportWebVitals from './reportWebVitals';
import {Footer} from "./components/footer";



ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Header/>
            <Routes>
                <Route path="/" element={<Main/>}/>
                <Route path="/projects" element={<Projects/>}/>
                <Route path="/team" element={<Team/>}/>
                <Route path="/faq" element={<Faq/>}/>
            </Routes>
        </Router>
        <Footer/>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
