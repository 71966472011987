/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Accordion } from "react-bootstrap";
import "./faq.scss";
export const Faq = () => {
    return (
        <div id="faq" className="faq container">
            <h2 className="py-5 text-center">Frequently Asked Questions</h2>
            <div className="row justify-content-center">
                <Accordion className="col-md-9" defaultActiveKey="0" flush>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>What is Solana Mobile, and Saga</Accordion.Header>
                        <Accordion.Body>
                            Solana Mobile is a subsidiary of Solana Labs. Solana Mobile is introducing a new premium
                            hardware mobile experience and web3 enabled software. "Saga" is a flagship-quality Android
                            device purpose-built for crypto. It's expected to ship early 2023. More details
                            on <a href="https://solanamobile.com/" target="_blank">SolanaMobile.com</a>.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Waht is Saga Hunt</Accordion.Header>
                        <Accordion.Body>
                            A utility focused project that will create a new wave of games. Simply put it is an on-chain
                            scavenger hunt game with participation from all across the globe. Expect: fun, loving community
                            and prizes with SagaHunt.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>What do I need to know in order to play a Saga Hunt?</Accordion.Header>
                        <Accordion.Body>
                            The Saga phone will be release in early 2023, and as we develop in parallel to Solana Labs,
                            you can now chip in and obtain your pass by minting an OG NFT.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>When is mint date?</Accordion.Header>
                        <Accordion.Body>
                            Mint date will be announced soon. Follow us <a href="https://twitter.com/SagaHunt" target="_blank">@SagaHunt</a> to stay up to date.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Where can I find clues</Accordion.Header>
                        <Accordion.Body>
                            You're a hunter and a tweep, and so are we!
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>I need to know more</Accordion.Header>
                        <Accordion.Body>
                            We are publishing a roadmap and adding more content  to the website frequently. Follow us and
                            stay engaged and you'll get all you need to know soon!
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    );
};